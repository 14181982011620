@import '../styles/variables.less';
@import './global/variables.less';

#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;
  .gx-sidebar-content {
    flex: 1.5;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    & > .ant-menu {
      & > .ant-menu-item {
        margin: 10px 0;

        & > .menu-icons {
          font-size: 14px;
        }
        .anticon {
          font-size: 13px;
        }
        .anticon + span {
          margin-left: 0;
          font-size: 14px;
        }
      }
      //for background color commented for now
      // & > .ant-menu-submenu-selected .ant-menu-submenu-title {
      //   background-color: #e6faff;
      // }
      & > .ant-menu-submenu {
        margin: 12px 0;
        padding: 0;
        .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          width: 100%;
          & > .anticon {
            font-size: 20px;
          }
          & > span {
            font-size: 18px;
          }
        }
      }
      & > .ant-menu-item::after {
        border-right: none;
      }
      & > .ant-menu-item:first-child {
        .ant-layout-sider-collapsed & {
          margin: 13px 0;
        }
      }
      & > .ant-menu-submenu:first-child {
        .ant-layout-sider-collapsed & {
          margin: 13px 0;
        }
      }
      & > .ant-menu-submenu {
        margin: 10px 0;
        .ant-layout-sider-collapsed & {
          margin: 33px 0;
        }
        padding: 0;
        .ant-menu-submenu-title {
          width: 100%;
          .anticon {
            font-size: 13px;
          }
          & > span {
            font-size: 14px;
            margin-left: 0;
          }
        }
      }
    }

    .ant-menu-inline-collapsed {
      width: auto;

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          .anticon {
            font-size: 22px;
          }
          & > span {
            display: unset;
            font-size: 14px;
            margin-left: 0;
          }
        }
      }
      .ant-menu-item {
        margin: 33px 0;
        .anticon {
          font-size: 22px;
        }
        & > span {
          font-size: 14px;
          margin-left: 0;
        }
      }
    }
  }

  .gx-sidebar-content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .gx-linebar {
    & > .ant-menu {
      & > .ant-menu-item {
        margin: 10px 0;

        & > .menu-icons {
          font-size: 14px;
        }

        & > span {
          font-size: 12px;
        }
      }
      & > .ant-menu-item::after {
        border-right: none;
      }
      & > .ant-menu-submenu {
        margin: 10px 0;
        padding: 0;
        .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          width: 100%;
          & > .anticon {
            font-size: 14px;
          }
          & > span {
            font-size: 14px;
          }
        }
      }
    }
    & > .gx-icon-btn {
      text-align: center;
      margin: 12px 0;
      padding-left: 20px;
      & > span {
        & > span {
          margin-right: 20px;
          transition: right 500ms;
        }
      }
    }
  }
}

.ant-table-wrapper {
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.import-register-form {
  .ant-form-item-label {
    padding: 0;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

.portal-header {
  text-transform: capitalize;
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  width: 700px;
  height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

label {
  font-weight: 700;
  max-width: 100%;
}

//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.p-0 {
  padding: 0;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-baseline {
  align-items: baseline;
}
.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

.card-body-p-24 {
  .ant-card-body:first-child {
    padding: 24px;
  }
}

//width classes
.width-percent-10 {
  width: 10%;
}

.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}

.width-22 {
  width: 22px;
}

.width-40 {
  width: 40px;
}

.width-32 {
  width: 32px;
}
//width classes

// height
.full-height-card {
  min-height: calc(100vh - 56px);
  background-color: #f5f6f9;
  border: none;
  .search-checkbox {
    display: flex;
    height: 24px;
    align-items: center;
  }

  .common-checkbox {
    height: 32px;
    font-size: 10px;
    color: #72788f;
    font-weight: 600;
    .ant-checkbox + span {
      padding: 0 9px;
      align-self: center;
      width: 135px;
    }
    .ant-checkbox-inner::after {
      left: 27%;
    }
    .ant-checkbox-inner {
      height: 24px;
      width: 24px;
    }
    .check-info {
      margin-left: 6px;
      vertical-align: bottom;
    }
  }

  .header-buttons {
    min-width: 153px;
  }
  .api-log-header {
    min-width: 85px;
  }
  .common-table {
    .ant-table-placeholder {
      height: calc(100vh - 263px);
    }
  }

  .card-container {
    .card-title {
      margin-left: 6px;
    }
    .ant-tabs-tab {
      color: @primary-color;
      height: 28px;
      font-weight: 500;
    }
    .ant-tabs-card .ant-tabs-content {
      background-color: @white-color;
      font-size: 12px;
      height: calc(100vh - 132px);
      overflow-y: auto;
      margin-top: -16px;
      border-radius: 6px;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.02);
    }
    .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
      padding: 16px;
      background: #fff;
    }
    .ant-tabs-card > .ant-tabs-nav::before {
      display: none;
    }

    [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
      height: 120px;
      margin-top: -8px;
    }
    .panel-search-box {
      font-weight: 500;
      border-radius: 6px;
      height: 24px;
      width: 100%;
      .ant-input {
        font-size: 10px;
      }
      .ant-input::placeholder {
        color: #8b8d95;
      }
      .anticon-search {
        color: #898c94;
      }
    }
    .panel-search-box.ant-input-affix-wrapper-sm {
      padding: 2px 11px;
    }
  }

  .required-row {
    margin-bottom: 32px;
  }
  .optional-divider {
    margin-left: 8px;
  }

  .form-divider {
    margin-top: 10px;
    margin-bottom: 16px;
  }
  .form-divider-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  // common class for view page detail
  .field-detail {
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
    min-height: 51px;
    margin-bottom: 16px;
    .field-label {
      margin-bottom: 8px;
      color: @text-color;
      font-size: 14px;
    }
    .field-value {
      height: 32px;
      color: @text-detail;
      font-size: 13px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .field-logo {
      height: 61px;
      width: 64px;
    }
  }
}

.list-search-box {
  font-weight: 500;
  border: none;
  border-radius: 6px;
  height: 24px;
  width: 100%;
  .ant-input {
    font-size: 10px;
  }
  .ant-input::placeholder {
    color: #8b8d95;
  }
  .anticon-search {
    color: #898c94;
  }
}

.list-search-box-cpq.ant-input-affix-wrapper-sm {
  padding: 2px 11px;
}

// height

.card-body-p-0 {
  .ant-card-body:first-child {
    padding: 0;
  }
}

.card-body-p-20 {
  .ant-card-body:first-child {
    padding: 20px;
  }
}

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style

//display style
.display-none {
  display: none;
}
//display style

// line clamp
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-left: 5px;
}
//line clamp

// date
.date-time {
  color: #999999;
  font-size: 9px;
}
// date

.mb-16 {
  margin-bottom: 16px;
}

.common-button {
  padding: 4px 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  box-shadow: 0px 5px 10px rgba(51, 129, 189, 0.25);
  img {
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .anticon {
    font-size: 12px;
  }
}
.ant-btn-primary[disabled].common-button {
  color: @white-color;
  background: #a3a3ad;
}

.export-button {
  width: 67px;
}
.import-button {
  width: 70px;
  margin-left: 8px;
  margin-right: 8px;
}

// commented for now.kept for future use
// .export-btn-dropdown {
//   .ant-dropdown-menu-item-active {
//     background-color: @secondary-color-light;
//   }
// }

.max-width-column {
  max-width: 400px;
}

.import-common-modal {
  font-family: @font-family;
  .module-text {
    color: #72788f;
    font-size: 10px;
  }
  .module-select {
    margin-top: 3px;
    margin-bottom: 16px;
  }
  .link-btn {
    font-size: 12px;
    margin-left: 4px;
    margin-right: 8px;
    color: @primary-color;
    cursor: pointer;
    text-decoration: underline;
  }
  .info-icon {
    font-size: 14px;
    cursor: pointer;
  }
  .ant-modal-close-x {
    width: 30px;
    margin-right: 15px;
  }
  .import-title {
    margin-right: 35px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    color: #f5222d;
  }
  .ant-upload-list-picture-card
    .ant-upload-list-item-actions
    .anticon-delete:hover {
    color: #f5222d;
  }
}

.info-tooltip {
  font-family: @font-family;
  color: #2c2f37;
  width: 337px;
  .ant-popover-title {
    font-weight: 600;
  }
  .ant-popover-inner-content {
    padding: 12px 12px 12px 0;
  }
}

.custom-filter-dropdown {
  font-family: @font-family;
  padding: 12px;
  width: 254px;
  word-wrap: break-word;
  .filter-search {
    width: 100%;
    margin-bottom: 12px;
  }
  .divider-filter {
    margin: 10px 0px;
  }
  .ok-button {
    width: 69px;
  }
  .reset-button {
    width: 69px;
    margin-right: 9px;
    border-radius: 6px;
    color: @primary-color;
    background-color: @field-background-color;
    border: none;
  }
  .filter-checkboxes {
    margin-top: 10px;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    .single-checkbox-div {
      margin-bottom: 9px;
      .single-checkbox {
        .ant-checkbox-inner {
          border: none;
          border-radius: 5px;
          background-color: @secondary-color-light;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: @primary-color;
        }
      }
    }
    .ant-checkbox-wrapper {
      font-size: 13px;
      word-break: break-word;
    }
  }
  .selected-divider {
    font-size: 13px;
    margin: 0px;
  }
  .filter-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filter-tag {
      margin: 4px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      span:first-child {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.discard-button {
  border: none;
  color: @primary-color;
  margin-right: 8px;
  box-shadow: none;
  background-color: @field-background-color;
}
.action-button {
  .ant-popover-inner-content {
    padding: 0;
    min-width: 103px;
  }
  .ant-btn {
    color: @text-detail-secondary;
    text-align: left;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background-color: @secondary-color-light;
  }
}
