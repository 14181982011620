@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.api-log-wrapper {
  .api-export-btn {
    margin-left: 15px;
  }

  .api-log-details {
    margin-top: 25px;

    .params-info {
      max-height: calc(100vh - 519px);
      width: 100%;
      overflow-y: scroll;
      color: @text-detail;
      font-weight: 500;
      font-size: 13px;
    }

    .info-divider {
      border-left: 1px solid @text-color;
      height: 100%;
    }
  }
}
