.tenant-module {
  .tenant-form {
    .ant-form-item-label {
      padding: 0;
      margin: 0;
    }
    .ant-col-24.ant-form-item-label {
      padding: 0;
    }
  }
  .list-img {
    margin-right: 8px;
    margin-bottom: 3px;
  }
  .lob-label {
    cursor: pointer;
    text-decoration: underline;
  }

  .has-scroll-data-search {
    .ant-table {
      .ant-table-body {
        height: calc(100vh - 307px);
      }
    }
  }

  .tenant-details {
    padding: 20px;
  }
}

.tenant-login-modal {
  .icon-color {
    color: rgba(0,0,0,.25);
  }
}