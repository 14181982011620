.search-component {
  .anticon {
    &.anticon-close-circle {
      &.ant-input-clear-icon {
        margin-left: 2px;
        text-align: center;
      }
    }
  }
}
