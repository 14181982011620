@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.plan-module {
  .bullet-text {
    margin-bottom: 10px;
    line-height: 16px;
    color: #535353;
    font-size: 14px;
    font-weight: 700;
  }
  .delete-icon {
    margin-left: 4px;
    font-size: 14px;
    color: @field-error;
  }
}
