@import '../../styles/variables.less';

.google-upload {
  .uploaded-file {
    .ant-upload-list {
      background-color: @white-color;
    }
    .ant-upload.ant-upload-drag {
      display: none;
    }
    .ant-upload-list {
      border: 1px dashed @upload-border-secondary;
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      border: none;
    }
  }
  .ant-upload.ant-upload-drag {
    min-height: 128px;
    margin-bottom: 16px;
    border-radius: 2px;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 18px;
  }
  .import-btn {
    padding: 4.5px 8px;
    font-size: 10px;
    box-shadow: 0px 5px 10px rgba(51, 129, 189, 0.25);
    border-radius: 5px;
    width: 93px;
    height: 24px;
    font-weight: 600;
  }
}
