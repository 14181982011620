.user-profile {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
  .ant-col-24.ant-form-item-label {
    padding: 0;
  }

  .user-details {
    padding: 20px;
  }
}
